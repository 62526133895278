import { useNavigate } from "react-router-dom";

export default function AnchorLink({
  text,
  anchorId,
  className,
  onClickHandler,
}) {
  const navigate = useNavigate();

  const handler = () => {
    if (onClickHandler) {
      onClickHandler();
    }
    navigateIfNeeded();
    scrollToSection();
  };

  const navigateIfNeeded = () => {
    const invalidUrls = ["mentions-legales", "cgu"];

    const currentUrl = window.location.href;
    const needToNavigate =
      invalidUrls.findIndex((x) => {
        return currentUrl.indexOf(x) > -1;
      }) > -1;

    if (needToNavigate === true) {
      navigate("/", {
        preventScrollReset: true,
        replace: false,
        relative: true,
      });
    }
  };

  const scrollToSection = () => {
    const element = document.getElementById(anchorId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const classes = !className
    ? "cursor-pointer hover:text-vacansign-white"
    : className;

  return (
    <>
      <button className={classes} onClick={handler}>
        {text}
      </button>
    </>
  );
}
