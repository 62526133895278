export const plansData = [
    {
        id: 'free',
        name: `Essai gratuit`,
        discretion: `Essayez gratuitement le logiciel pendant un mois !`,
        price: 0,
        frequency: "",
        features: [
            `Essai gratuit d'un mois`,
            `Toutes les fonctionnalités`,
        ],
    },
    {
        id: 'subscription',
        name: `Abonnement`,
        discretion: `Pour des besoins de signature réguliers, sans engagement.`,
        price: 5,
        frequency: "/mois",
        features: [
            `Gestion locative`,
            `Taxes de séjour`,
            `Contrats & signature illimités`,
            `Module comptabilité`,
            `Paiement au mois`,
            `Sans engagement`,
            `Support client`
        ],
    },
];
