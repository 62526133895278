import Hero from "./components/Hero";
import Features from "./components/Features";
import Plans from "./components/Plans";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import HowItWorks from "./components/HowItWorks";
import CallToAction from "./components/CallToAction";
import EmbeddedVideo from "./components/EmbeddedVideo";
import BlogList from "./BlogList";

export default function Home() {
  return (
    <div className="pt-14">
      <Hero />
      <Features />
      <HowItWorks />
      <EmbeddedVideo />
      <CallToAction />
      <Plans />
      <Faq />
      <Contact />
      <BlogList />
    </div>
  );
}
