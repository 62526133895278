import { useState } from "react";
import { Link } from "react-router-dom";
import AnchorButton from "../pages/components/buttons/AnchorButton";
import AnchorLink from "../pages/components/buttons/AnchorLink";

const anchors = [
  // {
  //   id: "section-features",
  //   name: "Fonctionnalités",
  // },
  // {
  //   id: "section-how-it-works",
  //   name: "Fonctionnement",
  // },
  // {
  //   id: "section-demo",
  //   name: "Démo",
  // },
  // {
  //   id: "section-plans",
  //   name: "Tarification",
  // },
  // {
  //   id: "section-faq",
  //   name: "Faq",
  // },
  // {
  //   id: "section-plans",
  //   name: "Inscription",
  //   type: "cta",
  // },
];

function RouteLinkItems({ routeLinks }) {
  return (
    <div className="hidden md:flex">
      <ul className="flex flex-1 justify-end items-center gap-12 text-vacansign-anthracite text-sm">
        {routeLinks.map((page, i) => (
          <li key={`key-${page.href}-${i}`}>
            {page.type === "cta" && (
              <AnchorButton text={page.name} anchorId={page.id} />
            )}
            {page.type !== "cta" && (
              <AnchorLink text={page.name} anchorId={page.id} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

const BlogNavigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  function MobileRouteLinkItems({ routeLinks }) {
    return (
      <ul className="sm:hidden block justify-end items-center text-vacansign-anthracite pb-4 pl-2 space-y-4">
        {routeLinks.map((page) => (
          <li key={page.href}>
            {/* <NavLink
              to={page.href}
              // className="text-3xl px-4 pt-2"
              className={`text-3xl px-4 pt-2 duration-200 transition-colors ${
                page.type === "cta"
                  ? "text-center inline-flex items-center justify-center w-full max-w-sm px-4 py-2 bg-vacansign-anthracite text-vacansign-white hover:bg-vacansign-white hover:text-vacansign-anthracite rounded-full"
                  : "cursor-pointer hover:text-vacansign-white"
              }`}
              onClick={closeMobileMenu}
            >
              {page.name}
            </NavLink> */}
            {/* <AnchorButton text={page.name} anchorId={page.id} key={page.id} /> */}
            {page.type === "cta" && (
              <AnchorButton
                text={page.name}
                anchorId={page.id}
                key={page.id}
                onClickHandler={closeMobileMenu}
              />
            )}
            {page.type !== "cta" && (
              <AnchorLink
                text={page.name}
                anchorId={page.id}
                key={page.id}
                onClickHandler={closeMobileMenu}
                className="text-3xl px-4 pt-2 duration-200 transition-colors cursor-pointer hover:text-vacansign-white"
              />
            )}
          </li>
        ))}
      </ul>
    );
  }

  function MobileButtonToggle() {
    return (
      <div className="md:hidden flex items-center">
        <button
          onClick={toggleMobileMenu}
          className="text-vacansign-anthracite"
        >
          <i className="text-2xl fas fa-bars cursor-pointer"></i>
        </button>
      </div>
    );
  }
  return (
    <nav className="bg-vacansign-yellow  w-full shadow-sm z-10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="py-0 my-0" onClick={closeMobileMenu}>
              <img
                className="w-18 h-8"
                src="../logo-full-text.svg"
                alt="Logo"
              />
            </Link>
          </div>

          <RouteLinkItems routeLinks={anchors} />

          <MobileButtonToggle />
        </div>
      </div>
      {isMobileMenuOpen && <MobileRouteLinkItems routeLinks={anchors} />}
    </nav>
  );
};
export default BlogNavigation;
