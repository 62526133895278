import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import './tailwind.output.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './shared/Layout';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import CGU from './pages/CGU';
import MentionsLegales from './pages/MentionsLegales';
import Blog from './pages/Blog';
import ScrollToTop from './helpers/ScrollToTop';
import BlogLayout from './shared/BlogLayout';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="cgu" element={<CGU />} />
          <Route path="mentions-legales" element={<MentionsLegales />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/blog" element={<BlogLayout />}>
          <Route index element={<Blog />} />
          <Route path=":id" element={<Blog />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}