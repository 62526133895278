import { useState } from "react";

import { faqData } from "./data/faq-data";

function ItemsList({ questions, onToggle }) {
  return (
    <div className="flex flex-col w-full max-w-screen-lg  bg-white rounded-xl shadow-sm border border-slate-100">
      {questions.map((q, i) => (
        <div
          key={q.id}
          className={`flex flex-col w-full max-w-screen-lg p-6 md:p-8 ${
            i > 0 ? "border-t" : ""
          }`}
        >
          <div className="flex-shrink-0 space-y-2">
            <div
              className="flex flex-row items-center justify-center cursor-pointer"
              onClick={() => onToggle(q.id, !q.isOpen)}
            >
              <h2 className="flex-1 text-lg md:text-xl text-vacansign-anthracite py-0">
                {q.title}
              </h2>
              <i
                className={`text-xl text-vacansign-anthracite fas fa-circle-chevron-up transition-transform duration-500 ${
                  !q.isOpen ? "rotate-180" : ""
                }`}
              ></i>
            </div>
            <p
              className={`text-sm transition-all duration-700 ${
                q.isOpen ? "py-4 h-auto opacity-100" : "p-0 h-0 opacity-0"
              }`}
            >
              {q.answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function Faq() {
  const [list, setList] = useState(faqData);

  function handleToggle(questionId, isOpen) {
    setList(
      list.map((q) => {
        if (q.id === questionId) {
          return { ...q, isOpen: isOpen };
        } else {
          return q;
        }
      })
    );
  }

  return (
    <section id="section-faq" className="bg-vacansign-white py-10 sm:py-14">
      <div className="w-full sm:w-3/4 mx-auto px-2">
        <h1 className="text-center text-vacansign-anthracite font-light pb-8">
          Des questions&nbsp;?
        </h1>
        <p className="text-center text-lg text-vacansign-anthracite my-4">
          Vous cherchez des réponses à vos questions&nbsp;? Vous êtes au bon
          endroit&nbsp;!
          <br />
          En plus, chez nous, on aime bien expliquer les choses, pour ce que
          tout soit clair pour vous&nbsp;!
        </p>
        <div className="flex flex-col items-center justify-center mt-16 gap-6">
          <ItemsList questions={list} onToggle={handleToggle} />
        </div>
        <p className="text-center text-sm text-vacansign-anthracite py-16">
          Vous ne trouvez pas de réponses à votre question ? N'hésitez pas à
          nous contacter directement !
        </p>
      </div>
    </section>
  );
}
