import "./Hero.css";
import AnchorButton from "./buttons/AnchorButton";

const items = [
  <>
    générez et signez vos contrats de location saisonnière en quelques
    secondes&nbsp;;
  </>,
  <>synchronisez votre calendrier Airbnb&nbsp;;</>,
  <>gérez vos réservations&nbsp;;</>,
  <>et bien d'autres choses encore&hellip;</>,
];

function FeatureItems({ features }) {
  return (
    <ul className="flex-1 space-y-2 pt-2">
      {features.map((feature, i) => (
        <li className="flex items-start" key={`item-${i}`}>
          <i className="text-vacansign-anthracite fas fa-check-circle pt-1"></i>
          <span className="text-vacansign-anthracite ml-3 text-base font-medium">
            {feature}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default function Hero() {
  return (
    <section className="hero-bg w-full bg-vacansign-yellow py-4 lg:py-6 gap-0 justify-center mx-auto">
      <div className="max-w-2xl lg:max-w-7xl grid xs:grid-cols-1 lg:grid-cols-2 mx-auto">
        <div className="flex flex-col justify-center p-4 w-full px-4 pb-2 md:pb-6 ">
          <p className="subtext text-vacansign-anthracite">
            Vous êtes propriétaire d'un logement que vous proposez en location
            saisonnière&nbsp;?
          </p>
          <h2 className="text-4xl md:text-6xl text-vacansign-anthracite font-light pt-4 pb-2">
            Signez rapidement vos contrats de location&nbsp;!
          </h2>
          <p className="subtext pt-4 text-vacansign-anthracite">
            Vacansign est la solution cloud qui va vous simplifier la vie&nbsp;:
          </p>
          <FeatureItems features={items} />
          <div className="md:justify-start justify-center pt-6 flex gap-2">
            <AnchorButton text="Je m'inscris" anchorId="section-plans" />
          </div>
        </div>
        <div className="w-full h-auto ">
          <img
            src="img/3d-business-boy-with-laptop.png"
            key="ddf"
            alt="homme souriant"
            className="m-auto transform transition duration-500 sm:scale-125 hidden sm:block sm:w-2/3"
          />
        </div>
      </div>
    </section>
  );
}
