export default function MentionsLegales() {
  return (
    <div className="pt-14">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">
          Mentions Légales - Vacansign
        </h1>

        <h2 className="text-xl font-bold mb-2">Éditeur du site</h2>
        <p className="mb-2">Vacansign</p>
        <p className="mb-2">5Bis rue François Périer</p>
        <p className="mb-2">34070 Montpellier</p>
        <p className="mb-2">info@vacansign.fr</p>
        <p className="mb-2">SIRET 424 845 949</p>

        <h2 className="text-xl font-bold mb-2">Directeur de publication</h2>
        <p className="mb-4">M. Quentin Villevieille</p>

        <h2 className="text-xl font-bold mb-2">Hébergement</h2>
        <p className="mb-4">AWS Paris</p>
        <p className="mb-4">
          Tour Carpe Diem, 31 Pl. des Corolles, 92400 Courbevoie
        </p>

        <h2 className="text-xl font-bold mb-2">Propriété intellectuelle</h2>
        <p className="mb-4">
          Le contenu de ce site (textes, images, logos, etc.) est protégé par
          les lois sur la propriété intellectuelle et appartient à Vacansign ou
          à ses partenaires. Toute reproduction ou utilisation non autorisée du
          contenu est strictement interdite.
        </p>

        <h2 className="text-xl font-bold mb-2">Données personnelles</h2>
        <p className="mb-4">
          Les données personnelles collectées sur ce site sont traitées
          conformément à notre politique de confidentialité. Vous disposez d'un
          droit d'accès, de rectification et de suppression de vos données
          personnelles en nous contactant à l'adresse suivante :{" "}
          <a href="mailto:info@vacansign.fr">info@vacansign.fr</a>.
        </p>

        <h2 className="text-xl font-bold mb-2">Cookies</h2>
        <p className="mb-4">
          Ce site utilise des cookies pour améliorer votre expérience de
          navigation. Vous pouvez configurer votre navigateur pour bloquer les
          cookies ou être averti lorsque des cookies sont envoyés. Pour en
          savoir plus, veuillez consulter notre politique de cookies.
        </p>

        <h2 className="text-xl font-bold mb-2">Limitation de responsabilité</h2>
        <p className="mb-4">
          Nous nous efforçons de fournir des informations précises sur ce site,
          mais nous ne pouvons garantir leur exhaustivité, leur actualité ou
          leur exactitude. En utilisant ce site, vous acceptez que nous ne
          soyons pas responsables des erreurs ou omissions dans les informations
          fournies.
        </p>

        <p className="mt-8">
          Pour toute question concernant les mentions légales, veuillez nous
          contacter à l'adresse suivante :{" "}
          <a href="mailto:info@vacansign.fr">info@vacansign.fr</a>.
        </p>
      </div>
    </div>
  );
}
