import { NavLink } from "react-router-dom";

export default function LinkButton({ text, url }) {
  return (
    <>
      <NavLink
        to={url}
        className="duration-200 transition-colors text-center inline-flex items-center justify-center w-full max-w-sm px-4 py-2 bg-vacansign-anthracite text-vacansign-white hover:bg-vacansign-white hover:text-vacansign-anthracite rounded-full"
      >
        {text}
      </NavLink>
    </>
  );
}
