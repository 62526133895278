const WebAppUrlService = {
  baseUrl: process.env.REACT_APP_WEBAPP_URL,

  getRegisterUrl: (registerUrlParams) => {
    const registerUrl = `${WebAppUrlService.baseUrl}/register`;

    return `${registerUrl}?sub=${registerUrlParams}`;
  },
};
export default WebAppUrlService;
