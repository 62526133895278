import "./CallToAction.css";
import SubscribeButton from "./buttons/SubscribeButton";

export default function CallToAction() {
  return (
    <section className="bg-white py-4 sm:py-10 px-4">
      <div className="cta-bg bg-vacansign-yellow-light max-w-2xl lg:max-w-7xl mx-auto p-6 rounded-xl relative">
        <h1 className="text-center text-vacansign-anthracite pb-8">
          Intéressé&middot;e&nbsp;?
        </h1>
        <p className="text-center text-lg text-vacansign-anthracite my-4">
          Inscrivez-vous gratuitement et testez notre outil&nbsp;!
        </p>
        <div className="justify-center pt-6 flex gap-2">
          <SubscribeButton text="Je crée mon compte" />
        </div>
        <img
          src="img/business-3d-black-businessman-in-blue-suit-giving-thumbs-up.png"
          key="close-up of black businessman in blue suit giving thumbs up"
          alt="homme souriant"
          className="hidden sm:block absolute bottom-0 right-4 h-72"
        />
      </div>
    </section>
  );
}
