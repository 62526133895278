import { articles } from "./articles";
import CardComponent from "./components/card/CardComponent";

export default function BlogList() {
  function getArticleUrl(article) {
    return `/blog/${article.url}`;
  }

  function getSubText(article) {
    return (
      <>
        <i className="fa-regular fa-calendar"></i> Publié {article.date} -{" "}
        <i className="fa-regular fa-clock"></i> {article.duration}
      </>
    );
  }

  function ArticlesList() {
    return articles
      .filter((x) => x.published === true)
      .map((article) => (
        <>
          <CardComponent
            key={article.id}
            title={article.title}
            subtext={getSubText(article)}
            description={article.description}
            link={getArticleUrl(article)}
            linkText="Lire l'article"
          />
        </>
      ));
  }

  return (
    <>
      <section
        key="blog-section"
        id="section-blog"
        className="plans-bg py-4 sm:py-10"
      >
        <div className="text-center max-w-7xl pt-4 lg:pt-6 mx-auto">
          <h1 className="text-center pt-4 lg:pt-6 mx-auto">
            <i className="fa-solid fa-square-rss"></i> Le blog
          </h1>
          <p className="pb-8">
            Retrouvez ici des articles concernant la location saisonnière mais
            aussi l'actualité de Vacansign.
          </p>
          {/* <div className="w-full lg:max-w-7xl mx-auto px-2"> */}
          <div className="flex flex-col items-center justify-center mt-6 space-y-4 lg:flex-row lg:items-stretch lg:space-x-4 lg:space-y-0">
            <ArticlesList />
          </div>
        </div>
      </section>
    </>
  );
}
