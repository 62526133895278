export default function CGU() {
  return (
    <div className="pt-14">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">
          Conditions générales d'utilisation - Vacansign
        </h1>
        <p className="mb-4">Date d'entrée en vigueur : le 1er juillet 2023.</p>

        <h2 className="text-xl font-bold mb-2">1. Acceptation des CGU</h2>
        <p className="mb-4">
          En utilisant le Site et l'Application, vous déclarez avoir lu, compris
          et accepté les présentes CGU dans leur intégralité. Si vous n'acceptez
          pas les CGU, vous ne pourrez pas utiliser le Site et l'Application.
        </p>

        <h2 className="text-xl font-bold mb-2">2. Utilisation de Vacansign</h2>
        <p className="mb-4">
          a. Eligibilité : Vous devez être majeur et avoir la capacité légale de
          conclure un contrat pour utiliser le Site et l'Application. Si vous
          agissez au nom d'une entité, vous déclarez avoir l'autorité nécessaire
          pour lier cette entité aux présentes CGU.
        </p>
        <p className="mb-4">
          b. Compte utilisateur : Vous pouvez être amené à créer un compte
          utilisateur pour accéder et utiliser certaines fonctionnalités du Site
          et de l'Application.
        </p>

        <h2 className="text-xl font-bold mb-2">
          3. Responsabilités de l'utilisateur
        </h2>
        <p className="mb-4">
          a. Vous êtes entièrement responsable de l'exactitude des informations
          que vous fournissez lors de l'utilisation de Vacansign, notamment lors
          de la rédaction et de la signature des contrats.
        </p>
        <p className="mb-4">
          b. Vous acceptez de respecter toutes les lois applicables lors de
          l'utilisation de Vacansign et de vous conformer aux obligations
          légales découlant de vos contrats.
        </p>

        <h2 className="text-xl font-bold mb-2">4. Propriété intellectuelle</h2>
        <p className="mb-4">
          Vacansign et tous les éléments associés, y compris le contenu, les
          logos, les marques de commerce et les droits d'auteur, sont la
          propriété exclusive de Vacansign. Vous ne pouvez pas reproduire,
          distribuer ou utiliser ces éléments sans autorisation préalable.
        </p>

        <h2 className="text-xl font-bold mb-2">
          5. Confidentialité des données
        </h2>
        <p className="mb-4">
          Nous nous engageons à protéger la confidentialité de vos données
          personnelles conformément à notre politique de confidentialité.
          Cependant, nous ne pouvons garantir une sécurité absolue des données
          transmises via Internet.
        </p>

        <h2 className="text-xl font-bold mb-2">
          6. Limitation de responsabilité
        </h2>
        <p className="mb-4">
          Nous ne serons pas tenus responsables des dommages directs, indirects,
          spéciaux, accessoires ou consécutifs résultant de l'utilisation de
          Vacansign ou de l'impossibilité de l'utiliser.
        </p>

        <h2 className="text-xl font-bold mb-2">7. Modifications des CGU</h2>
        <p className="mb-4">
          Nous nous réservons le droit de modifier les présentes CGU à tout
          moment. Les modifications seront effectives dès leur publication sur
          le Site. Il est de votre responsabilité de consulter régulièrement les
          CGU pour être informé des mises à jour.
        </p>

        <h2 className="text-xl font-bold mb-2">8. Résiliation</h2>
        <p className="mb-4">
          Nous nous réservons le droit de résilier votre accès à Vacansign à
          tout moment, sans préavis, en cas de violation des CGU ou de
          comportement inapproprié.
        </p>

        <h2 className="text-xl font-bold mb-2">
          9. Droit applicable et juridiction compétente
        </h2>
        <p className="mb-4">
          Les présentes conditions générales d'utilisation sont régies et
          interprétées conformément aux lois en vigueur en France. Tout litige
          découlant de l'utilisation du Site et de l'Application ou lié aux
          présentes CGU sera soumis à la compétence exclusive des tribunaux de
          Montpellier.
        </p>

        <p className="mt-8">
          Pour toute question concernant les présentes CGU, veuillez nous
          contacter à l'adresse suivante :{" "}
          <a href="mailto:info@vacansign.fr">info@vacansign.fr</a>.
        </p>
      </div>
    </div>
  );
}
