import { plansData } from "./data/plans-data";
import { NavLink } from "react-router-dom";
import WebAppUrlService from "./../../services/webAppUrlService";
import "./Plans.css";

function PlanFeatureItems({ planFeatures }) {
  return planFeatures.map((feature) => (
    <li className="flex items-start" key={feature}>
      <svg
        className="w-6 h-6 text-vacansign-yellow"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <span className="ml-3 text-base font-medium">{feature}</span>
    </li>
  ));
}
function PlanItems({ plans }) {
  return plans.map((plan) => (
    <div
      key={plan.id}
      className="flex flex-col bg-white w-full max-w-sm p-12 space-y-6 rounded-xl shadow-sm hover:shadow-md transition-shadow border border-slate-100"
    >
      <div className="flex-shrink-0 pb-6 space-y-2 border-b border-b-vacansign-yellow-light">
        <h2 className="text-3xl pt-0 pb-6">{plan.name}</h2>
        <p className="plan-discretion">{plan.discretion}</p>
      </div>
      <ul className="flex-1 space-y-4">
        <PlanFeatureItems planFeatures={plan.features} />
      </ul>
      <div className="flex-shrink-0 pt-4">
        <h2 className="pt-10 text-right">
          <span>{plan.price}</span>&nbsp;€
          <span className="text-lg">{plan.frequency}</span>
        </h2>
      </div>
      <div className="flex-shrink-0 pt-4">
        <NavLink
          to={WebAppUrlService.getRegisterUrl(plan.id)}
          className="inline-flex items-center justify-center w-full max-w-xs px-4 py-2 bg-vacansign-yellow-light hover:bg-vacansign-yellow transition-colors border rounded-full focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-vacansign-anthracite"
        >
          {plan.price === 0 && <>Je m'inscris</>}
          {plan.price > 0 && <>Je souscris</>}
        </NavLink>
      </div>
    </div>
  ));
}

const Plans = () => {
  return (
    <section
      key="plans-section"
      id="section-plans"
      className="plans-bg py-4 sm:py-10"
    >
      <div className="max-w-2xl lg:max-w-7xl mx-auto px-2">
        <h1 className="text-center text-vacansign-anthracite pt-0 pb-8">
          Tarification
        </h1>
        <div className="flex flex-col items-center justify-center mt-6 space-y-8 lg:flex-row lg:items-stretch lg:space-x-4 lg:space-y-0">
          <PlanItems plans={plansData} />
        </div>
      </div>
    </section>
  );
};
export default Plans;
