const steps = [
  {
    id: 1,
    step: "1",
    icon: "fa-person",
    title: `Je m'inscris`,
    description: (
      <>
        Je crée mon compte utilisateur en indiquant mes coordonnées (nom, prénom
        et adresse e-mail).
        <br />
        Je me connecte si j'ai déjà un compte.
      </>
    ),
  },
  {
    id: 2,
    step: "2",
    icon: "fa-house",
    title: `Informations du bien loué`,
    description: (
      <>
        Je renseigne la fiche de mon bien en location (adresse, surface,
        services, ...).
      </>
    ),
  },
  {
    id: 3,
    step: "3",
    icon: "fa-calendar",
    title: `Détails de réservation`,
    description: (
      <>
        Je remplis les informations de ma réservation avec notamment la période
        concernée, le tarif convenu et l'identité de mon locataire.
      </>
    ),
  },
  {
    id: 4,
    icon: "fa-pen-nib",
    step: "4",
    title: `Contrat & signature*`,
    description: (
      <>
        Je lance la création du contrat et de la signature en un clic. Je signe.
        Le locataire signe. Fin. Vous pouvez maintenant éteindre votre
        ordinateur et profiter de la vie en toute sérénité&nbsp;!
      </>
    ),
  },
];

function StepItems({ steps }) {
  return (
    <div className="max-w-xl mx-auto pl-10 sm:pl-0">
      <ol className="relative text-vacansign-anthracite border-l border-gray-200 text-xl pl-4">
        {steps.map((s) => (
          <li className="mb-10 ml-10" key={s.id}>
            <span className="absolute flex items-center justify-center w-10 h-10 bg-vacansign-yellow-light rounded-full -left-5 ring-6 ring-white">
              <i className={`fas ${s.icon} text-white`}></i>
            </span>
            <h3 className="pt-1 font-medium leading-tight text-2xl">
              {s.step}.&nbsp;{s.title}
            </h3>
            <p className="text-lg pt-2 pb-4">{s.description}</p>
          </li>
        ))}
      </ol>
      <p className="text-vacansign-anthracite-lighter text-sm text-center">
        * Pour signer, vous devez avoir un abonnement en cours de validité.
      </p>
    </div>
  );
}

export default function HowItWorks() {
  return (
    <section
      id="section-how-it-works"
      className="bg-white py-10 sm:py-14 max-w-7xl mx-auto"
    >
      <div className="w-full lg:max-w-7xl mx-auto px-2">
        <h1 className="text-center text-vacansign-anthracite pb-8">
          Comment ça marche&nbsp;?
        </h1>
        <div className="flex flex-col md:flex-row">
          <img
            src="img/3d-business-young-man-got-an-idea.png"
            alt="jeune homme souriant qui a une idée"
            className="m-auto pb-4 md:p-0 w-full sm:w-1/2"
          />
          <StepItems steps={steps} />
        </div>
      </div>
    </section>
  );
}
