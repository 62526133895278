import { useNavigate } from "react-router-dom";

export default function AnchorButton({ text, anchorId }) {
  const navigate = useNavigate();

  const navigateIfNeeded = () => {
    const invalidUrls = ["mentions-legales", "cgu"];

    const currentUrl = window.location.href;
    const needToNavigate =
      invalidUrls.findIndex((x) => {
        return currentUrl.indexOf(x) > -1;
      }) > -1;

    if (needToNavigate === true) {
      navigate("/", {
        preventScrollReset: true,
        replace: false,
        relative: true,
      });
    }
  };

  const scrollToSection = () => {
    navigateIfNeeded();
    const element = document.getElementById(anchorId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <button
        className="duration-200 transition-colors text-center inline-flex items-center justify-center w-full max-w-sm px-4 py-2 bg-vacansign-anthracite text-vacansign-white hover:bg-vacansign-white hover:text-vacansign-anthracite rounded-full"
        onClick={scrollToSection}
      >
        {text}
      </button>
    </>
  );
}
