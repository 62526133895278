const featuresData = [
  {
    id: "contracts",
    icon: "fa-arrow-pointer",
    title: `Vos contrats en un clic`,
    description: (
      <>
        <p>
          Ne perdez pas votre temps à rédiger vos contrats de location,
          Vacansign s'en charge pour vous en moins d'une seconde&nbsp;!
        </p>
      </>
    ),
  },
  {
    id: "e-signature",
    icon: "fa-pen-nib",
    title: <>Signez en ligne</>,
    description: (
      <p>
        Signez facilement vos contrats de location instantanément à l'aide de la
        signature électronique&nbsp;!
      </p>
    ),
  },
  {
    id: "taxes",
    icon: "fa-euro",
    title: <>Voir vos taxes de séjour</>,
    description: (
      <p>
        Visualisez rapidement les nuitées à déclarer ainsi que le montant de la
        taxe de séjour à reverser chaque mois.
      </p>
    ),
  },
  {
    id: "dashboard",
    icon: "fa-eye",
    title: <>Contrôlez vos locations</>,
    description: (
      <p>
        Suivez vos règlements clients, listez vos dépenses et gardez l'&oelig;il
        sur vos revenus grâce aux tableaux de bord.
      </p>
    ),
  },
  {
    id: "invoices",
    icon: "fa-file-invoice",
    title: <>Emettez des factures</>,
    description: (
      <>
        <p>Vos voyageurs professionnels vous demandent une facture&nbsp;?</p>
        <p>Pas de souci, Vacansign sait faire&nbsp;!</p>
      </>
    ),
  },
  {
    id: "improve",
    icon: "fa-arrows-rotate",
    title: <>Un service qui évolue&hellip;</>,
    description: (
      <>
        <p>
          Grâce à vous, Vacansign va évoluer et vous proposera toujours une
          expérience améliorée&nbsp;!
        </p>
      </>
    ),
  },
];

function FeatureItemsList({ features }) {
  return (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-4">
      {features.map((f) => (
        <div key={f.id} className="flex flex-col justify-between max-w-sm p-4">
          <div>
            <div className="flex flex-nowrap justify-start gap-2">
              <i className={`text-2xl text-vacansign-yellow fas ${f.icon}`}></i>
              <h2 className="font-light text-2xl text-vacansign-anthracite py-0">
                {f.title}
              </h2>
            </div>
            <div className="pt-4 pb-4 text-lg">{f.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function Features() {
  return (
    <section id="section-features" className="bg-white pt-10 sm:pt-14">
      <div className="w-full lg:max-w-7xl mx-auto px-2">
        <FeatureItemsList features={featuresData} />
      </div>
    </section>
  );
}
