export const faqData = [
    {
        id: 'q0',
        title: `A qui s'adresse VacanSign ?`,
        answer: `VacanSign souhaite les propriétaires particuliers qui louent leur logement en saisonnier et qui veulent éviter les plateformes (Airbnb, booking, etc) et leurs frais. VacanSign n'est pas un outil de mise en relation ; il vous permet de rédiger automatiquement des contrats saisonniers et de les signer avec vos locataires.`,
        isOpen: false,
    },
    {
        id: 'q1',
        title: `VacanSign est-il une plateforme de mise en relation ?`,
        answer: `Non, VacanSign ne met personne en relation. Il vous permet de signer des contrats avec vos locataires que vous connaissez déjà. Par exemple, l'outil s'avère vraiment utile lorsque vos locataires reviennent d'une année sur l'autre.`,
        isOpen: false,
    },
    {
        id: 'q2',
        title: 'Pourquoi signer un contrat de location saisonnière ?',
        answer: `Rédiger un contrat de location saisonnière, c'est avant tout fait pour vous protéger ! La signature électronique a une valeur juridique et constitue une preuve devant un tribunal.`,
        isOpen: false,
    },
    {
        id: 'q3',
        title: 'Pourquoi utiliser VacanSign plutôt que rédiger ses contrats soi-même ?',
        answer: `Utiliser VacanSign, c'est se simplifier la vie ! Les contrats sont validés et contiennent toutes les sections nécessaires pour éviter tout désagrément. Cela permettra également de rassurer vos locataires. Faites nous confiance, on s'occupe de vous fournir des contrats aux petits oignons et louez en tout sérénité !`,
        isOpen: false,
    },
];