export default function EmbeddedVideo() {
  return (
    <>
      <section
        id="section-demo"
        className="hero-bg w-full bg-vacansign-yellow px-2 py-4 lg:py-6 gap-0 mx-auto"
      >
        <h1 className="text-center max-w-7xl pt-4 lg:pt-6 mx-auto">
          Démonstration en vidéo
        </h1>
        <div className="max-w-full lg:max-w-7xl grid gap-4 grid-cols-1 md:grid-cols-2 mt-6 md:mt-8 mx-auto">
          <div className="flex flex-col justify-start max-w-xl">
            <p className="text-vacansign-anthracite text-base font-medium pb-4">
              Quoi de mieux qu'une petite démonstration en vidéo pour vous
              montrer comment ça marche&nbsp;?
            </p>
            <p className="text-vacansign-anthracite text-base font-medium">
              Au programme, on vous explique l'essentiel&nbsp;:
            </p>
            <ul className="flex-1 space-y-2 pt-2">
              <li className="flex items-start" key="item-0">
                <i className="text-vacansign-anthracite fas fa-check-circle pt-1"></i>
                <span className="text-vacansign-anthracite ml-3 text-base font-medium">
                  Comment faire pour créer une réservation&nbsp;?
                </span>
              </li>
              <li className="flex items-start" key="item-1">
                <i className="text-vacansign-anthracite fas fa-check-circle pt-1"></i>
                <span className="text-vacansign-anthracite ml-3 text-base font-medium">
                  Comment générer le contrat de location&nbsp;?
                </span>
              </li>
              <li className="flex items-start" key="item-2">
                <i className="text-vacansign-anthracite fas fa-check-circle pt-1"></i>
                <span className="text-vacansign-anthracite ml-3 text-base font-medium">
                  Comment signer le contrat en ligne&nbsp;?
                </span>
              </li>
            </ul>
            <p className="flex-1 text-vacansign-anthracite text-base font-medium pt-6">
              Et tout ça en moins de 5 minutes chrono s'il vous plaît&nbsp;!
            </p>
          </div>
          <div className="flex flex-col justify-between max-w-xl">
            <iframe
              width="560"
              height="315"
              className="w-full m-auto"
              src="https://www.youtube.com/embed/mOMdTFRXfIE?si=KQNUdHcqQsF7kFGM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}
