import { Link } from "react-router-dom";
import "./Footer.css";

const footerRoutes = [
  {
    name: "Mentions Légales",
    href: "/mentions-legales",
  },
  {
    name: "CGU",
    href: "/cgu",
  },
  {
    name: "Blog",
    href: "/blog",
  },
];
export default function Footer() {
  function LinkItems({ links }) {
    return (
      <ul className="flex gap-12 text-xs">
        {links.map((page) => (
          <li
            className="cursor-pointer hover:text-vacansign-yellow transition-colors duration-500"
            key={page.href}
          >
            <Link to={page.href}>{page.name}</Link>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <footer className="footer-bg py-8 bg-vacansign-anthracite text-white">
      <div className="container flex flex-col md:flex-row items-center">
        <div className="flex flex-1 flex-wrap items-center justify-center md:justify-start gap-12">
          <Link to="/">
            <span className="font-CastleBegale">VacanSign</span>
          </Link>
          <LinkItems links={footerRoutes} />
        </div>
        <ul className="flex gap-10 mt-12 md:mt-0">
          <li key="linkedin">
            <a
              href="https://www.linkedin.com/company/vacansign"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="text-white hover:text-vacansign-yellow text-2xl fab fa-linkedin transition-colors duration-500"></i>
            </a>
          </li>
          <li key="facebook">
            <a
              href="https://www.facebook.com/vacansign"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="text-white hover:text-vacansign-yellow text-2xl fab fa-facebook-square transition-colors duration-500"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
