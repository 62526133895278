export const articles = [
  {
    id: 1,
    published: true,
    url: "contrat-location-saisonniere-France-2024",
    date: "le 4 avril 2024",
    author: "Quentin",
    title: "Le contrat de location saisonnière en France en 2024",
    description:
      "Le contrat de location saisonnière, qui va lier deux parties (propriétaire et locataire), est un document important. Examinons en détail ce document à ne pas négliger.",
    subtitle: "Un document essentiel pour bien se protéger",
    duration: "5 minutes",
    content: `## Qu'est-ce qu'un contrat de location saisonnière ?

Un contrat de location saisonnière est un document écrit qui formalise les conditions de location d'un meublé de tourisme pour une courte durée (inférieure à 90 jours consécutifs). Il identifie et protège les deux parties : le propriétaire et le locataire.

## Est-ce obligatoire ?

 La loi française impose la signature d'un contrat écrit pour toute location saisonnière. En l'absence de contrat, les parties ne sont pas protégées et peuvent se retrouver en situation délicate en cas de litige.

 Vous l'aurez compris donc, la loi oblige les propriétaires à en faire.
 
## Sous quelle forme ?

Le contrat de location saisonnière peut être établi sous forme papier ou électronique. Il doit être signé par les deux parties et comporter un certain nombre de mentions obligatoires.

Voyons lequelles :

- Les informations relatives au logement (adresse, superficie, nombre de pièces, etc.)
- La période concernée par la location
- Le montant du loyer et les charges
- Le versement d'un acompte ou d’arrhes (s’il y en a)
- Les conditions d'annulation
- Les obligations du locataire (respect du règlement intérieur, etc.)
- Les obligations du propriétaire (mise à disposition du logement en bon état, etc.)

## Quels sont les risques à ne pas en faire ?

En l'absence de contrat, les parties se retrouvent sans protection juridique en cas de litige. Le propriétaire peut se voir refuser le paiement du loyer ou des charges, et le locataire peut se voir expulsé du logement sans préavis.

Par exemple, s’il y a des dégradations, comment prouver qu’il y avait bien un locataire à la date sans avoir fait de contrat ?

Et comment prouver qu’il est responsable de ces dégradations sans avoir fait d’état des lieux ?

## Pourquoi utiliser un logiciel plutôt que de tenter de rédiger soi-même un contrat ?

Il est tout à fait possible de rédiger soi-même un contrat de location saisonnière. Dans ce cas, il est préférable de bien s’informer. Si vous avez l’habitude de la location, vous savez quelles clauses inclure pour vous protéger.

Cela dit, tout le monde n'a pas cette expérience et un logiciel tel que Vacansign s’occupe de vous fournir un contrat valide et précis pour vous faire gagner du temps et de la tranquillité d'esprit.

Le logiciel propose aussi des clauses auxquelles on ne pense pas forcément lorsqu’on rédige soi-même un contrat. Par exemple, indiquer le lieu de la juridiction compétente en cas de litige permet d’éviter que le locataire puisse vous l’imposer.

Un état des lieux est automatiquement intégré au contrat et permet de se prémunir des dégradations.

La loi pouvant évoluer, le logiciel suivra cette évolution pour proposer à ses utilisateurs des contrats toujours en règle vis à vis de la législation française.

Vacansign inclut aussi nativement un processus de signature du contrat simple, rapide et sécurisé afin d'éviter les échanges par courrier postal.

## Pour conclure

Le contrat de location saisonnière est un document essentiel pour se protéger en cas de litige. Il définit les termes de la relation entre propriétaires et locataires et assure la protection des deux parties. Il doit être signé. Mais comment faire ? La signature électronique aurait-elle un rôle à jouer ? Est-ce qu'elle est valide et constitue une preuve devant un tribunal ?

C’est ce que nous verrons dans un prochain article.

---

## Sources et références

- [Location de vacances entre particuliers](https://www.service-public.fr/particuliers/vosdroits/F2045) - Consulté le 11 mars 2024

- [Section 1 : Meublés de tourisme (Articles L324-1 à L324-2-1) - Légifrance](https://www.legifrance.gouv.fr/codes/id/LEGISCTA000006158462/)
`,
  },
  {
    id: 2,
    published: true,
    url: "lancement-blog-vacansign",
    date: "le 4 avril 2024",
    author: "Quentin",
    title: "Lancement du blog de Vacansign",
    description:
      "Lancer un blog est un bon moyen de faire connaître son activité, d'autant plus lorsque celle-ci se fait 100% en ligne ! C'est également un bon moyen pour vous de rester informés car la location saisonnière évolue vite !",
    subtitle: "Un blog pour rester informé",
    duration: "3 minutes",
    content: `
Je suis vraiment ravi de vous annoncer le lancement du blog de Vacansign aujourd'hui !

## 🤔 A quoi s'attendre avec ce blog ?

Sur cet espace, je compte :

- vous informer sur l'avancement de mon projet Vacansign ;
- vous parler de l'actualité de la location saisonnière ;
- vous partager mes astuces.


Mon objectif est de vous proposer au moins un article par mois.

## 🚀 L'aventure Vacansign est en cours

Lancer une entreprise est une vraie aventure !

Suivez cette aventure sur les réseaux sociaux, mais aussi via des articles sur ce blog.

Par exemple, j'ai récemment participé à une soirée pour entrepreneurs, la *Nuit du Pitch*, qui s'est tenue à Lattes - à côté de Montpellier. J'y ai pu parler de mon activité et la faire connaître aux entrepreneurs locaux. Cela fera très certainement l'objet d'un article dédié dans les prochains jours.


En tout cas, n'hésitez pas à suivre mon blog !

*Quentin*
`,
  },
];
