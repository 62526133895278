import LinkButton from "../buttons/LinkButton";
import "./CardComponent.css";

export default function CardComponent({
  key,
  title,
  subtext,
  description,
  link,
  linkText,
}) {
  const uniqueKey = `card-${key}`;
  return (
    <>
      <div key={uniqueKey} className="card-container">
        <h2 className="card-title">{title}</h2>
        <p className="card-subtext">{subtext}</p>
        <p className="card-description">{description}</p>
        <div className="card-link">
          <LinkButton text={linkText} url={link} />
        </div>
      </div>
    </>
  );
}
