import { useState } from "react";
import toast from "react-hot-toast";
import ApiUrlService from "../../services/apiUrlService";

export default function Contact() {
  const [error, setError] = useState(null);

  function validateChange(event) {
    const emailAddress = event.target.value;
    if (!emailAddress) {
      setError(null);
      return;
    }

    if (validateEmailAddress(emailAddress) === false) {
      setError(<>Adresse e-mail invalide</>);
    } else {
      setError(null);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const emailAddress = event.target.email.value;

    if (validateEmailAddress === false) {
      return;
    }

    toast.promise(post(emailAddress), {
      loading: "Saving...",
      success: <>Email enregistré&nbsp;!</>,
      error: <>Une erreur s'est produite&nbsp;!</>,
    });
  }

  const validateEmailAddress = (emailAddress) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return true;
    }
    return false;
  };

  async function post(emailAddress) {
    const res = await fetch(ApiUrlService.getLeadUrl(), {
      method: "POST",
      body: JSON.stringify({ email: emailAddress }),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });
    if (res && res.status === 200) {
      return;
    }
    throw Error("test");
  }

  function ErrorBlock({ error }) {
    return (
      error && (
        <p className="pt-4 text-sm font-Prompt text-vacansign-white">{error}</p>
      )
    );
  }

  return (
    <section className="bg-vacansign-yellow m-0">
      <div className=" relative">
        <div className="container py-20">
          <div className="sm:w-3/4 lg:w-2/4 mx-auto">
            <h1 className="text-3xl text-center py-0">
              Je souhaite rester informé&middot;e&nbsp;!
            </h1>
            <form onSubmit={handleSubmit} onChange={validateChange}>
              <div className="flex flex-col sm:flex-row gap-6 mt-8">
                <input
                  type="email"
                  placeholder="Entrez votre adresse e-mail"
                  id="email"
                  className="focus:outline-none flex-1 px-2 py-3 rounded-md text-black"
                />
                <button
                  type="submit"
                  className="btn bg-vacansign-anthracite text-white shadow-sm hover:bg-vacansign-white hover:text-vacansign-anthracite"
                >
                  Informez-moi !
                </button>
              </div>
            </form>
            <ErrorBlock error={error} />
          </div>
        </div>
        <img
          src="img/business-3d-black-businesswoman-in-brown-suit-clapping-hands.png"
          className="hidden lg:block absolute bottom-0 left-4 lg:left-16 xl:left-36 h-72"
          alt="femme souriante"
        />
      </div>
    </section>
  );
}
