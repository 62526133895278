import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import { articles } from "./articles";
import BlogList from "./BlogList";
import "./Blog.css";

export default function Blog() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (!id) {
      setArticle(undefined);
      return;
    }
    const searchedArticle = articles.find((x) => x.url === id);
    if (!searchedArticle) {
      setArticle(undefined);
      return;
    }
    setArticle(searchedArticle);
  }, [id]);

  return (
    <>
      {!article && <BlogList />}
      {article && (
        <>
          <div id="article" className="pt-8 pb-6 bg-slate-50">
            <div className="max-w-screen-md px-4 m-auto bg-white rounded-xl shadow-sm">
              <h1>{article.title}</h1>
              <p className="font-Raleway text-3xl text-gray-700 pb-4 -mt-2">
                {article.subtitle}
              </p>
              <p className="text-vacansign-anthracite-light pb-4">
                Par {article.author}, {article.date} -{" "}
                <i className="fa-regular fa-clock"></i> {article.duration} de
                lecture
              </p>
              <p>{article.description}</p>
              <hr className="max-w-48 text-left mt-2 mb-4" />
              <Markdown className="pb-4">{article.content}</Markdown>
            </div>
          </div>
        </>
      )}
    </>
  );
}
